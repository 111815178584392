import React from 'react';
import PaySuccessIcon from '@/assets/img/product_details/pay_success_icon.svg';
import styles from './paymentSuccess.module.less';
import { QRCode } from 'antd';

const PaymentSuccess: React.FC = () => {
    return (
        <div className={styles.paySuccessPage}>
            <div className={styles.module1}>
                <div className={styles.paySuccessIconBox}>
                    <img src={PaySuccessIcon} className={styles.paySuccessIcon} />
                </div>
                <div className={styles.paySuccessTitle}>
                    支付成功
                </div>
                <div className={styles.paySuccessContent}>
                    感谢支持，祝您上课愉快！
                </div>
            </div>
            <div className={styles.module2}>
                <div className={styles.title}>
                    上课教程
                </div>
                <div className={styles.stepBox}>
                    <div className={styles.step}>
                        <div className={styles.stepNum}>1</div>
                        <div className={styles.stepContent}>
                            请在电脑或者pad上用浏览器访问：<br />
                            <a href="https://agi4kids.cn/home?utm=zlh" target="_blank" className={styles.link}>
                                https://agi4kids.cn/home?utm=zlh
                            </a>
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.stepNum}>2</div>
                        <div className={styles.stepContent}>
                            进入艾宝AI的主页，点击进入应用
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.stepNum}>3</div>
                        <div className={styles.stepContent}>
                            选择手机号登录注册，收到验证码后就可以进入应用,详情请看下图
                        </div>
                    </div>
                </div>
                <div className={styles.imgBox}>

                </div>
            </div>
            <div className={styles.module3}>
                <div className={styles.contactBox}>
                    <div className={styles.title}>
                        联系我们
                    </div>
                    <div className={styles.contactContent}>
                        扫描右边二维码联系我们
                    </div>
                </div>
                <div className={styles.qrBox}>
                    <QRCode value="https://agi4kids.cn/home?utm=zlh" className={styles.qrCode} />
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess;
