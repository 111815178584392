import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './productDetails.module.less';
import service from '@/services/axios';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { message } from 'antd';
import MobileVerificationModal from './MobileVerificationModal/MobileVerificationModal';
import Product1HeadImg from '@/assets/img/product_details/product_1_head.svg';
import Product1BodyImg from '@/assets/img/product_details/product_1_body.svg';
import { useParams, useNavigate } from 'react-router-dom';

const ProductDetails: React.FC = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const { userInfoStore } = useStores();
    const location = useLocation();
    const [payerOpenId, setPayerOpenId] = useState<string | null>(null);
    const [isBuying, setIsBuying] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [productDetails, setProductDetails] = useState<any | null>(null);

    //购买
    const handleBuy = async () => {
        if (!productDetails) {
            message.error('未获取到产品详情');
            console.error('未获取到产品详情');
            return;
        }
        if (!payerOpenId) {
            console.error('未获取到openid');
            return;
        }
        if (!phoneNumber) {
            setIsModalVisible(true);
            return;
        }
        if (isBuying) {
            return;
        }
        const data = {
            id: 0,
            user_id: userInfoStore.userInfoData.id,
            product_id: parseInt(productDetails.id),
            alternative_id: `${phoneNumber}`,
            id_type: "phone",
            total_amount: productDetails.price / 1000,
            original_price: productDetails.price / 1000,
            price: productDetails.price / 1000,
            currency: productDetails.currency,
            status: 1,
            payment_method: "wechat",
            openid: payerOpenId,
            extras: {
                ...(productDetails.extras?.utm !== undefined ? { utm: productDetails.extras.utm } : {})
            }
        }
        setIsBuying(true);
        try {
            const res: any = await service.post('/order', data);
            if (res.status === 'success') {
                // 调用微信支付  
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', () => {
                            invokeWeChatPay(res.data);
                        }, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', () => {
                            invokeWeChatPay(res.data);
                        });
                    }
                } else {
                    invokeWeChatPay(res.data);
                }
            } else {
                message.error('下单失败');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsBuying(false);
        }
    }
    // 调起微信支付
    const invokeWeChatPay = (data: any) => {
        const { timestamp, nonce_str, prepay_id, pay_sign, payment_id } = data;

        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            appId: "wx903d0bd19b6cbd96",
            timeStamp: timestamp,
            nonceStr: nonce_str,
            package: `prepay_id=${prepay_id}`,
            signType: "RSA",
            paySign: pay_sign
        }, function (res: any) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
                // 支付成功  
                console.log('支付成功');
                navigate('/pay_success');
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                // 支付取消
                console.log('支付取消');
                message.error('支付取消');
                const cancelData = {
                    id: payment_id,
                    status: 2,
                };
                service.put("/payment", cancelData);
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                // 支付失败
                console.log('支付失败');
                message.error('支付失败');
                const failData = {
                    id: payment_id,
                    status: 3,
                };
                service.put("/payment", failData);
            }
        });
    };

    const getWechatPayerOpenId = async (code: string) => {
        const res: any = await service.get(`/wechat_payer_openid/${code}`);
        if (res.status === 'success') {
            setPayerOpenId(res.data.openid);
        } else {
            console.error('获取openid失败');
        }
    }

    const getProductDetails = async () => {
        if (!productId) {
            console.error('未获取到产品id');
            return;
        }
        try {
            const res: any = await service.get(`/product/${productId}`);
            if (res.status === 'success') {
                setProductDetails(res.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        // 获取 URL 查询参数  
        const queryParams = new URLSearchParams(location.search);
        const codeParam = queryParams.get('code');
        if (codeParam) {
            getWechatPayerOpenId(codeParam);
        }
    }, [location.search]); // 依赖于 URL 查询参数变化 

    useEffect(() => {
        getProductDetails();
    }, [productId]);

    return (
        <div className={styles.productDetailsPage}>
            <div className={styles.headBox}>
                <img src={Product1HeadImg} alt="product" className={styles.productHeadImg} />
                <div className={styles.productPrice}>
                    <div className={styles.innerBox}>
                        <div className={styles.priceTitle}>新人优惠价</div>
                        <div className={styles.price}>
                            {productDetails ? `¥ ${productDetails.price / 1000}` : '¥ ***'}
                        </div>
                    </div>
                </div>
            </div>
            <img src={Product1BodyImg} alt="product" className={styles.productBodyImg} />
            <div className={styles.buyBtnBox}>
                <div
                    className={styles.buyBtn}
                    onClick={() => setIsModalVisible(true)}
                >
                    立即购买
                </div>
            </div>
            <MobileVerificationModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                verifySuccessCallback={handleBuy}
            />
        </div>
    )
}

export default observer(ProductDetails);
