import React, { useState, useEffect } from 'react';
import styles from './blindBox.module.less';
import BlindBoxCover from '@/assets/img/user_center/blind_box_cover.svg';
import { getBlindBox } from '@/api/userCenter';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import { useNavigate } from 'react-router-dom';
import BlindAnimateGif from '@/assets/img/user_center/blind_box_animate.gif';

const BlindBox: React.FC = () => {
    const navigate = useNavigate();
    const [blindBoxData, setBlindBoxData] = useState<any>({});
    const [isAnimating, setIsAnimating] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [gifSrc, setGifSrc] = useState<string>(BlindAnimateGif);

    const openBlindBox = async () => {
        if (isAnimating) return; // 防止连续点击 
        setIsAnimating(true);
        const startTime = Date.now();
        // 每次开启时给 GIF URL 添加一个时间戳以强制重新加载  
        setGifSrc(`${BlindAnimateGif}?t=${Date.now()}`);  

        try {
            const res: any = await getBlindBox();
            const endTime = Date.now();

            // Set the image URL returned from the API  
            if (res.status === "success") {
                setBlindBoxData(res.data);
                setImageUrl(res.data.url);
            }

            // Calculate duration and wait for at least 2 seconds  
            const duration = Math.max(2000 - (endTime - startTime), 0);
            setTimeout(() => {
                setIsAnimating(false);
            }, duration);
        } catch (error) {
            console.error('Error getting blind box', error);
            setIsAnimating(false);
        }
    }

    return (
        <div className={styles.blindBoxPage}>
            <div className={styles.goBack} onClick={() => navigate('/user')}>
                <img src={GoBackIcon} alt="go back" />
            </div>
            <div className={styles.main}>
                {!imageUrl && !isAnimating && (
                    <div className={styles.blindBox}>
                        <div className={styles.blindBoxCover}>
                            <img
                                src={BlindBoxCover}
                                alt="盲盒封面"
                            />
                            <div className={styles.emptyBox}></div>
                        </div>
                    </div>
                )}
                {isAnimating && (
                    <div className={styles.blindBoxAnimate}>
                        <img src={gifSrc} alt="盲盒动画" className={styles.blindBoxAnimate} />
                    </div>
                )}
                {imageUrl && !isAnimating && (
                    <img src={imageUrl} alt="盲盒内容" className={styles.resultImage} />
                )}
                <div className={styles.openBtn} onClick={openBlindBox}>
                    开启盲盒
                </div>
            </div>
        </div>
    )
}

export default BlindBox;