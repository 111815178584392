import { lectureStore } from '@/store/lectureStore';
import { userInfoStore } from '@/store/userInfoStore';
import CryptoJS from 'crypto-js';

// 处理流式数据
export const parseContent = (str: string): string => {
    const data = str.split('\n');
    let filteredArray = data.filter(item => item.trim().startsWith('data:'));
    let content = filteredArray.reduce((acc, item) => {
        let jsonStr = item.replace('data: ', '').trim();
        try {
            let parsedData = JSON.parse(jsonStr);
            let content = parsedData.data?.content ?? '';
            return acc + content;
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return acc;
        }
    }, '');
    return content;
}

// 生成随机ID
export const generateId = (): number => {
    return parseInt(Date.now().toString() + Math.floor(Math.random() * 1000));
}

// 选择题从前端传到后端处理
export const choiceFeToBackend = (originalData: any): any => {
    // TODO： 这个function需要移到别的地方并重写

    //const choices = originalData.choices.map(choice => choice.value);
    //const answers = !originalData.answers.length ? [] : originalData.answers.map(answer =>
    //    choices.indexOf(originalData.choices.find(choice => choice.id === answer)?.value || '')
    //);
    //return { ...originalData, choices, answers };
    return {};
}

// 判断text中是否包含{{}}
export const containsVariablePattern = (text: string): boolean => {
    const regex = /\{\{.*?\}\}/;
    return regex.test(text);
};

// 提取text中的变量名
const extractVariables = (text: string): string[] => {
    const regex = /(\{\{([^}]+)\}\})/g;
    let match;
    const variables: string[] = [];

    while ((match = regex.exec(text)) !== null) {
        variables.push(match[2]);
    }

    return variables;
};

// 重写文本
export const rewriteText = (text: string): string => {
    if (!lectureStore || !lectureStore.getLectureVar) {
        console.log("getLectureVar is null");
        return text;
    }

    const vars = extractVariables(text);
    let results: { [key: string]: any } = {};

    vars.forEach((v) => {
        if (!v.includes('.')) {
            let val = lectureStore.getLectureVar(v);
            if (Array.isArray(val)) {
                val = val.join(', ');
            }
            results[v] = val;
        } else {
            if (v.startsWith('@lectureStore.currentBlockContent')) {
                console.log('We are @lectureStore.currentBlockContent');
                results[v] = lectureStore.getCurrentBlockContent();
            } else if (v.startsWith('@userInfoStore.userInfoData.name')) {
                results[v] = userInfoStore.userInfoData.name;
            } else if (v.startsWith('@userInfoStore.userInfoData.id')) {
                results[v] = userInfoStore.userInfoData.id;
            } else {
                let [key, subkey] = v.split('.');
                if (lectureStore.getLectureVar(key)) {
                    let val = JSON.parse(lectureStore.getLectureVar(key));
                    if (subkey.includes('$')) {
                        let [subSubkey, replaceVal] = subkey.split('$');
                        const realSubkey = lectureStore.getLectureVar(subSubkey);
                        const realReplaceVal = lectureStore.getLectureVar(replaceVal);
                        if (realSubkey && realReplaceVal) {
                            results[v] = val[realSubkey].replace('@@TO_BE_REPLACED@@', realReplaceVal);
                        }
                    } else {
                        const realSubkey = lectureStore.getLectureVar(subkey);
                        if (val && val[realSubkey]) {
                            results[v] = val[realSubkey];
                        }
                    }
                }
            }
        }
    });

    vars.forEach((v) => {
        let pattern = v;
        if (v.includes('.') || v.includes('$')) {
            pattern = v.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        }
        const regex = new RegExp(`\\{\\{${pattern}\\}\\}`, 'g');
        text = text.replace(regex, results[v]);
    });

    return text;
};

// 转换 URL 为 CloudFront
export const convertToCloudFrontUrl = (url: string): string => {
    if (!url) {
        return url;
    }
    if (url.startsWith('https://agi4kids.s3.us-west-2.amazonaws.com')) {
        return url.replace('https://agi4kids.s3.us-west-2.amazonaws.com', 'https://dg3xe9d4k80p9.cloudfront.net');
    }
    return url;
}

export const convertToAliyunUrl = (url: string): string => {
    if (!url) {
        return url;
    } 
    if (url.startsWith('https://agi4kids.s3.us-west-2.amazonaws.com')) {
        return url.replace('https://agi4kids.s3.us-west-2.amazonaws.com', 'https://nf-course-data.oss-cn-beijing.aliyuncs.com');
    }
    return url;
}

export const convertUrl = (url: string): string => {
    const ossSite = import.meta.env.VITE_OSS_SITE;
    if (ossSite === 'Aliyun') {
        return convertToAliyunUrl(url);
    } else if (ossSite === 'CloudFront') {
        return convertToCloudFrontUrl(url);
    }
    return url;
}

// 生成MD5
export const generateMD5 = (str: string): string => {
    return CryptoJS.MD5(str).toString();
}


export const strTrimLeft = (str: string): string => {
    // trim the left part of str
    return str.replace(/^\s+/, "");
}

export const simplifyFileName = (fileFullName: string): string => {
    let simplifiedName = "";

    // 检查 URL 是否以指定的前缀开始  
    const baseUrl = 'https://agi4kids.s3.us-west-2.amazonaws.com/';
    if (fileFullName.startsWith(baseUrl)) {
        const segments = fileFullName.split('/');

        if (segments[3] === 'templates') {
            const lastSegment = segments.pop();
            const nameSegments = lastSegment ? lastSegment.split('-') : [];

            if (nameSegments.length > 0) {
                const templateName = nameSegments[1];

                // 处理模板名称长度  
                if (templateName.length <= 16) {
                    simplifiedName = templateName;
                } else {
                    const prefix = templateName.substring(0, 2);
                    const suffix = templateName.substring(templateName.length - 8);
                    simplifiedName = `${prefix}****${suffix}`;
                }
            }
        } else if (segments[3] === 'audios' || segments[3] === 'tts') {
            const lastSegment = segments.pop();
            const fileName = lastSegment ? lastSegment : '';

            // 处理文件名称长度  
            if (fileName.length <= 16) {
                simplifiedName = fileName;
            } else {
                const prefix = fileName.substring(0, 2);
                const suffix = fileName.substring(fileName.length - 8);
                simplifiedName = `${prefix}****${suffix}`;
            }
        }
    } else if (fileFullName.startsWith('/assets')) {
        // 处理本地文件名  
        const segments = fileFullName.split('/');
        const lastSegment = segments.pop();
        simplifiedName = lastSegment ? lastSegment : '';
    } else {
        // 保留完整的文件名  
        simplifiedName = fileFullName;
    }

    return simplifiedName;
}