import React, { useEffect, useState } from 'react';
import { Button, Switch, InputNumber, Input, Select } from 'antd'
import { cloneDeep } from 'lodash';
import styles from './aiChatModifier.module.less';
import MultimediaAPI from '@/api/multimedia';
import useCommonFunction from '@/hooks/useCommonFunction';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { ElementData } from '@/base/ElementData/ElementData';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import { Blank, ChatMessage, MediaInfo } from '@/base/ChatMessage';
import { PromptSetVarConfig } from '@/base/ElementData/UtilTypes';
import { ElementIdOption } from '@/hooks/useCommonFunction';


const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface AIChatModifierProps {
    focusedItem: ElementData<AIChatRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, type: string) => void;
}

const AIChatModifier: React.FC<AIChatModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex }) => {
    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions: ElementIdOption[] = generateElementIdOptions();
    const [messageType, setMessageType] = useState(1);
    const [isGeneratingTeacherAudio, setIsGeneratingTeacherAudio] = useState(false);
    const [currentGenAudioId, setCurrentGenAudioId] = useState(-1);
    const ttsAPI = new MultimediaAPI();
    // const [presetQuestions, setPresetQuestions] = useState(cloneDeep(focusedItem.content.preset_questions));
    const [presetMessages, setPresetMessages] = useState(cloneDeep(focusedItem.content?.presetMessages || []));
    // 目前我们实际只放1个postprocess handler
    const [postprocessHandlers, setPostprocessHandlers] = useState(cloneDeep(
        focusedItem.content.postProcessHandlers ? focusedItem.content.postProcessHandlers : []));

    const generateNewTeacherWordsAudio = async (text: string, messageId: number) => {
        try {
            setIsGeneratingTeacherAudio(true);
            setCurrentGenAudioId(messageId);
            const res = await ttsAPI.genVoiceAndUpload({ text: text }, 'tts');
            updateDoneCallFuncParams(messageId, 'audioUrl', res.file_url);
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
        } finally {
            setIsGeneratingTeacherAudio(false);
            setCurrentGenAudioId(-1);
        }
    }
    //生成填空
    const generateBlank = (messageId: number) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        newPresetMessages[messageIndex].generateBlanks();
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }
    //填空选项改变
    const blankChoiceChange = (index: number,
        messageId: number, key: keyof Blank, value: any) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        newPresetMessages[messageIndex].blanks[index].setField(key, value);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //添加预设消息
    const addPresetMessage = () => {
        const newPresetMessages = cloneDeep(presetMessages);
        console.log(newPresetMessages);
        newPresetMessages.push(generatePresetMessage(messageType)!);
        console.log(newPresetMessages);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //删除预设消息
    const deletePresetMessage = (messageId: number) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        newPresetMessages.splice(messageIndex, 1);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //更新预设消息
    const updatePresetMessage = (messageId: number, key: keyof ChatMessage, value: any) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        newPresetMessages[messageIndex].setField(key, value);
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //更新done_call_func的funcName
    const updateDoneCallFunc = (messageId: number, funcName: string) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        newPresetMessages[messageIndex].doneCallFunc.funcName = funcName;
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }

    //更新done_call_func的funcParams
    const updateDoneCallFuncParams = (messageId: number, key: string, value: any) => {
        const newPresetMessages = cloneDeep(presetMessages);
        const messageIndex = newPresetMessages.findIndex(item => item.id === messageId);
        const funcParams = newPresetMessages[messageIndex].doneCallFunc.funcParams;
        const newFuncParams = { ...funcParams, [key]: value };
        newPresetMessages[messageIndex].doneCallFunc.funcParams = newFuncParams;
        setPresetMessages(newPresetMessages);
        onInputChange(focusedItem.id, 'presetMessages', newPresetMessages);
    }


    //根据类型生成预设消息的结构
    const generatePresetMessage = (messageType: number) => {
        //1:预设gpt消息 2:预设问题 3:用户发送出去的消息 4:媒体类型消息

        if (messageType === 1) {
            return new ChatMessage({
                // id会默认生成
                type: 1,
                rawContent: "预设gpt消息",
                readStreaming: true,
                doneCallFunc: {},
                audioUrl: ''
            });
        } else if (messageType === 2) {
            return new ChatMessage({
                type: 2,
                rawContent: "预设问题",
                responseMethod: "preset",
                stream: true,
                readStreaming: false,
                doneCallFunc: {},
                audioUrl: ''
            });
        } else if (messageType === 4) {
            return new ChatMessage({
                type: 4,
                mediaInfo: new MediaInfo({ mediaType: 'image', mediaUrl: '' }),
                doneCallFunc: {},
                usedToSetVar: true,
            });
        }
    };

    const addPostProcessHandler = () => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers.push(
            new PromptSetVarConfig(
                { prompt: "prompt", destVar: "var0" + String(postprocessHandlers.length + 1) })
        );
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    const updatePostProcessHandler = (index: number, key: string, value: any) => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers[index].setField((key as keyof PromptSetVarConfig), value);
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    const deletePostProcessHandler = (index: number) => () => {
        const newPostProcessHandlers = cloneDeep(postprocessHandlers);
        newPostProcessHandlers.splice(index, 1);
        setPostprocessHandlers(newPostProcessHandlers);
        onInputChange(focusedItem.id, 'postProcessHandlers', newPostProcessHandlers);
    }

    useEffect(() => {
        // setPresetQuestions(cloneDeep(focusedItem.content.preset_questions))
        setPresetMessages(cloneDeep(focusedItem.content.presetMessages))
    }, [focusedItem.id])


    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>System Prompt</div>
                <textarea
                    value={focusedItem.content.systemPrompt || ''}
                    onChange={e => onInputChange(focusedItem.id, 'systemPrompt', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                />
                <div className={styles.modifyItemTitle}>高</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题内容</div>
                <Input
                    value={focusedItem.content.titleContent}
                    onChange={e => onInputChange(focusedItem.id, 'titleContent', e.target.value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.titleColor}
                    onChange={e => onInputChange(focusedItem.id, 'titleColor', e.target.value)}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题字体大小</div>
                <InputNumber
                    value={focusedItem.content.titleFontSize}
                    onChange={value => onInputChange(focusedItem.id, 'titleFontSize', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>用户消息的头像</div>
                <Select
                    value={focusedItem.content.userMessageAvatar || ""}
                    onChange={value => onInputChange(focusedItem.id, 'userMessageAvatar', value)}
                >
                    <Select.Option value="user">用户头像</Select.Option>
                    <Select.Option value="teacher">AI老师</Select.Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否需要预设问题完全返回</div>
                <Switch
                    checked={focusedItem.content.needFullAnswer}
                    onChange={value => onInputChange(focusedItem.id, 'needFullAnswer', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示播放预设问题按钮</div>
                <Switch
                    checked={focusedItem.content.readPresets}
                    onChange={value => onInputChange(focusedItem.id, 'readPresets', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示输入框</div>
                <Switch
                    checked={focusedItem.content.showInputArea}
                    onChange={value => onInputChange(focusedItem.id, 'showInputArea', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>多少个消息隐藏输入框</div>
                <InputNumber
                    value={focusedItem.content.numMsgToDisableInput}
                    onChange={value => onInputChange(focusedItem.id, 'numMsgToDisableInput', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>多少个消息可以继续</div>
                <InputNumber
                    value={focusedItem.content.numMsgToContinue}
                    onChange={value => onInputChange(focusedItem.id, 'numMsgToContinue', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>LLMCall是否带Context</div>
                <Switch
                    checked={focusedItem.content.useContext}
                    onChange={value => onInputChange(focusedItem.id, 'useContext', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否老师说完话之后展示第一个消息</div>
                <Switch
                    checked={focusedItem.content.showFirstMsgAfterTeacherSpeak}
                    onChange={value => onInputChange(focusedItem.id, 'showFirstMsgAfterTeacherSpeak', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>用户发送问题后是否朗读问题</div>
                <Switch
                    checked={focusedItem.content.shouldReadQuestion}
                    onChange={value => onInputChange(focusedItem.id, 'shouldReadQuestion', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否读出返回结果</div>
                <Switch
                    checked={focusedItem.content.shouldDoTts}
                    onChange={value => onInputChange(focusedItem.id, 'shouldDoTts', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>gpt的音色</div>
                <Select
                    className={styles.functionSelect}
                    value={focusedItem.content.ttsVoice || ""}
                    onChange={value => onInputChange(focusedItem.id, 'ttsVoice', value)}
                >
                    {voiceList.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <label>chat tools</label>
                <textarea
                    value={focusedItem.content.chatTools || ""}
                    onChange={e => onInputChange(focusedItem.id, 'chatTools', e.target.value)}
                    className={styles.textarea_small}
                />
            </div>
            {presetMessages?.map((item, index) => (
                <div
                    className={styles.verticalLayout}
                    key={String(focusedItem.id) + String(index + 1)}
                    style={{ border: "1px solid black" }}
                >
                    <h3>
                        当前预设消息类型：
                        {item.type === 1 && '预设gpt消息'}
                        {item.type === 2 && '预设问题'}
                        {item.type === 4 && '媒体类型消息'}
                    </h3>
                    {
                        (item.type === 1 || item.type === 2) && (
                            <>
                                <div className={styles.modifyItem}>
                                    <div className={styles.modifyItemTitle}>内容</div>
                                    <textarea
                                        value={item.rawContent}
                                        onChange={e => updatePresetMessage(item.id, 'rawContent', e.target.value)}
                                        className={styles.textareaLarge}
                                    />
                                </div>
                                <div className={styles.modifyItemCol}>
                                    <TextToSpeech
                                        text={item.rawContent}
                                        btnText='生成音频'
                                        successCallback={(url) => updatePresetMessage(item.id, 'audioUrl', url)}
                                        ttsVoice={item.messageVoice}
                                        ttsVoiceChangeCallback={(value) => updatePresetMessage(item.id, 'messageVoice', value)}
                                    />
                                </div>
                                <div className={styles.modifyItem}>
                                    <div className={styles.modifyItemTitle}>当前是否已有音频链接:</div>
                                    {item.audioUrl ? "是" : "否"}
                                </div>
                            </>
                        )
                    }
                    {
                        (
                            item.type === 1 ||
                            (item.type === 2 && item.responseMethod === 'gpt') ||
                            item.type === 4
                        ) && (
                            <>
                                <div className={styles.modifyItemCol}>
                                    <div className={styles.modifyItemTitle}>
                                        {item.type === 1 && "当前消息完成时执行的操作"}
                                        {item.type === 2 && "当前问题回答完成时执行的操作"}
                                        {item.type === 4 && "当前消息完成时执行的操作"}
                                    </div>
                                    <Select
                                        value={item.doneCallFunc?.funcName}
                                        onChange={value => updateDoneCallFunc(item.id, value)}
                                        className={styles.functionSelect}
                                    >
                                        {optionsData.map(option => (
                                            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                        ))}
                                    </Select>
                                </div>
                                {
                                    (item.doneCallFunc?.funcName === 'showSpecifiedElement' ||
                                        item.doneCallFunc?.funcName === 'showSpecifiedElementAndHide' ||
                                        item.doneCallFunc?.funcName === 'changeTeacherWords') && (
                                        <div className={styles.modifyItemCol}>
                                            <div className={styles.modifyItemTitle}>elementId</div>
                                            <Select
                                                value={item.doneCallFunc?.funcParams?.elementId}
                                                onChange={value => updateDoneCallFuncParams(item.id, 'elementId', value)}
                                                className={styles.functionSelect}
                                            >
                                                {elementIdOptions.map(option => (
                                                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    )
                                }
                                {
                                    item.doneCallFunc?.funcName === 'changeTeacherWords' && (
                                        <>
                                            <div className={styles.modifyItemCol}>
                                                <div className={styles.modifyItemTitle}>参数:new words</div>
                                                <textarea
                                                    value={item.doneCallFunc?.funcParams?.newWords}
                                                    onChange={e => updateDoneCallFuncParams(item.id, 'newWords', e.target.value)}
                                                    className={styles.textareaSmall}
                                                />
                                            </div>
                                            <div className={styles.modifyItemCol}>
                                                <div className={styles.modifyItemTitle}>参数:audio url</div>
                                                <Button
                                                    onClick={() => generateNewTeacherWordsAudio(item.doneCallFunc?.funcParams?.newWords, item.id)}
                                                    loading={isGeneratingTeacherAudio && currentGenAudioId === item.id}
                                                >
                                                    生成音频
                                                </Button>
                                            </div>
                                            <div className={styles.modifyItem}>
                                                <div className={styles.modifyItemTitle}>当前是否已有音频链接:</div>
                                                {item.doneCallFunc?.funcParams?.audioUrl ? "是" : "否"}
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    item.doneCallFunc?.funcName === 'navigate' && (
                                        <div className={styles.modifyItemCol}>
                                            <div className={styles.modifyItemTitle}>url</div>
                                            <Input
                                                value={item.doneCallFunc?.funcParams?.url}
                                                onChange={e => updateDoneCallFuncParams(item.id, 'url', e.target.value)}
                                            />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        item.type === 1 && (
                            <div>
                                <div className={styles.modifyItem}>
                                    <div className={styles.modifyItemTitle}>是否流式读出</div>
                                    <Switch
                                        checked={item.readStreaming}
                                        onChange={value => updatePresetMessage(item.id, 'readStreaming', value)}
                                    />
                                </div>
                                <div className={styles.modifyItem}>
                                    <div className={styles.modifyItemTitle}>是否流式展示但不读出</div>
                                    <Switch
                                        checked={item.streamingShow}
                                        onChange={value => updatePresetMessage(item.id, 'streamingShow', value)}
                                    />
                                </div>
                            </div>
                        )
                    }

                    {item.type === 2 && (
                        <div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>回答方式</div>
                                <Select
                                    value={item.responseMethod}
                                    onChange={value => updatePresetMessage(item.id, 'responseMethod', value)}
                                >
                                    <Select.Option value='preset'>预设回答</Select.Option>
                                    <Select.Option value='gpt'>大模型回答</Select.Option>
                                    <Select.Option value='gpt-check'>大模型回答&Check</Select.Option>
                                </Select>
                            </div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>是否重复预设问题</div>
                                <Switch
                                    checked={item.shouldRepeatPreset}
                                    onChange={value => updatePresetMessage(item.id, 'shouldRepeatPreset', value)}
                                />
                            </div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>用户是否可以编辑消息</div>
                                <Switch
                                    checked={item.userCanEditRawContent}
                                    onChange={value => updatePresetMessage(item.id, 'userCanEditRawContent', value)}
                                />
                            </div>
                            {item.responseMethod === 'gpt' && (
                                <div className={styles.modifyItem}>
                                    <div className={styles.modifyItemTitle}>是否stream</div>
                                    <Switch
                                        checked={item.stream}
                                        onChange={value => updatePresetMessage(item.id, 'stream', value)}
                                    />
                                </div>
                            )}
                            {item.responseMethod === 'gpt-check' && (
                                <div className={styles.modifyItemCol}>
                                    <div className={styles.modifyItemTitle}>chat tools</div>
                                    <textarea
                                        value={item.tools}
                                        onChange={e => updatePresetMessage(item.id, 'tools', e.target.value)}
                                        className={styles.textareaSmall}
                                    />
                                </div>
                            )}
                            <Button onClick={() => generateBlank(item.id)} type='primary'>生成填空</Button>
                            <h3>填空选项(用|隔开)</h3>
                            {item.blanks?.map((blank, index) => (
                                <div key={String(focusedItem.id) + String(index + 1)}>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>填空{index + 1}</div>
                                        <Input
                                            value={blank.choicesStr}
                                            onChange={e => blankChoiceChange(index, item.id, 'choicesStr', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>对于这个空的描述或提示</div>
                                        <Input
                                            value={blank.descriptionAboutBlank}
                                            onChange={e => blankChoiceChange(index, item.id, 'descriptionAboutBlank', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>预填选项</div>
                                        <Input
                                            value={blank.selectedChoice!}
                                            onChange={e => blankChoiceChange(index, item.id, 'selectedChoice', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>是否允许用户输入自定义填空</div>
                                        <Switch
                                            checked={blank.allowCustomInput}
                                            onChange={value => blankChoiceChange(index, item.id, 'allowCustomInput', value)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {
                        item.type === 4 && (
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>媒体信息</div>
                                <textarea
                                    value={item.mediaInfo?.mediaUrl || ""}
                                    onChange={e => updatePresetMessage(item.id, 'mediaInfo', e.target.value)}
                                    className={styles.textareaLarge}
                                />
                            </div>
                        )
                    }
                    <br></br>
                    <Button onClick={() => deletePresetMessage(item.id)} danger>删除</Button>
                </div>
            ))}
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>选择类型点击添加</div>
                <Select
                    value={messageType}
                    onChange={value => setMessageType(value)}
                >
                    <Select.Option value={1}>gpt消息</Select.Option>
                    <Select.Option value={2}>问题</Select.Option>
                    <Select.Option value={4}>媒体类型消息</Select.Option>
                </Select>
                <Button onClick={addPresetMessage} type='primary' className={styles.addMessageBtn}>添加消息</Button>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
            <hr></hr>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Chat Set LectureVars</div>
                <textarea
                    value={focusedItem.content.targetVarsStr || ""}
                    onChange={e => onInputChange(focusedItem.id, 'targetVarsStr', e.target.value)}
                    className={styles.textarea_small}
                />
            </div>
            <hr></hr>
            <h4>Postprocess Handlers</h4>
            {postprocessHandlers.map((handler, index) => (
                <div className={styles.verticalLayout} key={String(focusedItem.id) + String(index + 1)}>
                    <div key={index}>
                        <label>prompt </label>
                        <textarea
                            value={handler.prompt}
                            onChange={e => updatePostProcessHandler(index, 'prompt', e.target.value)}
                            className={styles.textareaPrompt} />
                        <label>destination Lecture var </label>
                        <textarea
                            value={handler.destVar}
                            onChange={e => updatePostProcessHandler(index, 'destVar', e.target.value)}
                            className={styles.textareaVar} />
                        <label>mode </label>
                        <textarea
                            value={handler.mode}
                            onChange={e => updatePostProcessHandler(index, 'mode', e.target.value)}
                            className={styles.textareaVar} />
                    </div>
                    <br></br>
                    <Button onClick={deletePostProcessHandler(index)}>删除</Button>
                </div>
            ))}
            <hr></hr>
            <Button onClick={addPostProcessHandler}>添加预设Postprocess Handler</Button>
        </div>
    )
}

export default AIChatModifier;
