import React, { useEffect, useRef } from 'react';
import styles from './weChatLogin.module.less';
import { useMediaQuery } from 'react-responsive';

declare global {
    interface Window {
        WxLogin: any;
    }
}

interface WeChatLoginProps {
    utm: string | null;
};

const WeChatLogin: React.FC<WeChatLoginProps> = ({ utm }) => {
    console.log("WeChatLogin", utm);
    if (!utm) {
        utm = '';
    }
    let wxLoginObj = useRef(null);
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isPad = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1279px)' });
    const isPC = useMediaQuery({ query: '(min-width: 1280px)' });

    useEffect(() => {
        let padCss = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'
        let pcCss = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDMwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30g'
        // 这个 useEffect 在组件挂载时执行  
        wxLoginObj.current = new window.WxLogin({
            self_redirect: true,
            id: 'wx_login_container',  // 这里是你想要放置二维码的 DOM 元素的 ID  
            appid: 'wx583180cbef808fee',
            scope: 'snsapi_login',
            redirect_uri: encodeURIComponent('https://agi4kids.cn/wx_auth/'), // 这里是你的回调地址
            state: Math.ceil(Math.random() * 1000), // 可用的状态信息, 在验证后返回  
            style: 'black', // 可以添加样式  
            href: isPad ? padCss : pcCss // 可以添加自定义链接  
        });

        return () => {
            // 清理以防止内存泄漏  
            wxLoginObj.current = null;
        }
    }, [isPad, isPC]);

    return (
        <div id="wx_login_container" className={styles.wxLoginContainer}></div> // 这里是你想要放置二维码的 DOM 元素
    );
};

export default WeChatLogin;