import LLMCallRequestData from '@/base/LLMCallRequestData';
import service from '@/services/axios';

// 封装发送请求的函数
const requestLLMCall = async (data: LLMCallRequestData) => {
    console.log("requestLLMCall", data.toJSON());
    //创建参数
    console.log(data);
    //判断是否为开发环境
    // const url = isDevelopment ? "/api/llmcall" : "http://ec2-54-185-82-50.us-west-2.compute.amazonaws.com:21010/llmcall";
    const url = "/api/llmcall";
    const options = {
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data.toJSON())
    };

    try {
        const response = await fetch(url, options);
        console.log("LLMCall response", response);
        return response;
    } catch (error: any) {
        console.error(`Error in fetch: ${error.message}`);
    }
}

const requestLLMCallV2 = async (data: LLMCallRequestData, mode: number = 0) => {
    try {
        let serviceUrl: string = '/llmcall';
        if (mode === 1) {
            serviceUrl = '/llmcallv2';
        }
        const response = await service.post(serviceUrl, data.toJSON());
        console.log('response in requestLLMCallV2', response.data);
        return response.data;
    } catch (error) {
        console.error('Error in requestLLMCallV2:', error);
        throw error;
    }
}

const searchWithLLMCall = async (data: LLMCallRequestData) => {
    try {
        let serviceUrl: string = '/search_with_llm';
        const response = await service.post(serviceUrl, data.toJSON());
        console.log('response in searchWithLLMCall', response.data);
        return response.data;
    } catch (error) {
        console.error('Error in searchWithLLMCall:', error);
        throw error;
    }
}

export { requestLLMCall, requestLLMCallV2, searchWithLLMCall };
