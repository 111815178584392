import React, { useState, useEffect, useRef } from 'react';
import styles from './studentBlock.module.less';
import RenderElement from '@/components/Element/common/RenderElement';
import { Button } from 'antd';
import TeacherChatModal from '@/components/TeacherChatModal/TeacherChatModal';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import MaoPaoSound from '@/assets/audio/btnClick_maopao.mp3';
import { cloneDeep, throttle } from 'lodash';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import HomeIcon from '@/assets/img/home.svg';
import FirstPageIcon from '@/assets/img/first_page.svg';
import { useNavigate, useParams } from 'react-router-dom';
// import mixpanel from 'mixpanel-browser';
import { convertUrl } from '@/utils/utils';
import service from '@/services/axios';
import UserPng from '@/assets/img/user.png';
import UserManagementAPI from '@/api/userManagement';
import CoinsIcon from "@/assets/img/user_center/coins_icon.svg";
import DiamondIcon from "@/assets/img/user_center/diamond_icon.svg";
import { BlockTraceData } from '@/base/BlockData';
import AIChatRegionContent from '@/base/ElementData/AIChatRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import { ElementContent, ElementData } from '@/base/ElementData/ElementData';
import DataDefinition from '@/base/DataDefinition';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';
import { ChatMessage } from '@/base/ChatMessage';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { requestLLMCallV2 } from '@/services/requestLLMCall';
import ClearRecordModal from '@/components/ClearRecordModal/ClearRecordModal';
import useCommonFunction from '@/hooks/useCommonFunction';
import MultimediaAPI from '@/api/multimedia';
import { genUUIDv4 } from '@/utils/id_generator';

const StudentBlock: React.FC = observer(() => {
    const { lectureStore } = useStores();
    const { sectionId } = useParams();
    const userManagementAPI = new UserManagementAPI();
    // 从lectureStore读取learnedBlockList， originBlockList
    const { learnedBlockList, originBlockList } = lectureStore;
    const currentBlock = learnedBlockList[lectureStore.currentBlockIndex];
    const isLastBlock = lectureStore.currentBlockIndex + 1 === originBlockList.length;
    //console.log('StudentBlock -- lectureStore', lectureStore);
    const { commonStatusStore, userInfoStore } = useStores();
    const [showOperationArea, setShowOperationArea] = useState(true);
    const [timeoutFlag, setTimeoutFlag] = useState(false);
    const continueBtnClickRef = useRef(new Audio(MaoPaoSound));//按钮点击音效
    const btnGroupRef = useRef<{ handleSubmit: () => Promise<void> } | null>(null);
    const AIChatRef = useRef();
    const [showSubmitBtn, setShowSubmitBtn] = useState(false);
    const [hasCorrectAnswer, setHasCorrectAnswer] = useState(false);
    const [hasGenSongElement, setHasGenSongElement] = useState(false);
    const [hasChatGptElement, setHasChatGptElement] = useState(false);
    const [hasDragClassifyElement, setHasDragClassifyElement] = useState(false);
    //const [isLastBlock, setIsLastBlock] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // 初始高度可以设置成 window.innerHeight - 相应值  
    const [containerHeight, setContainerHeight] = useState(window.innerHeight - (window.innerWidth > 1280 ? 110 : 90));
    const navigate = useNavigate();
    const [operationAreaHeight, setOperationAreaHeight] = useState(0);
    //存储老师的话elementData
    const [teacherWordsElementData, setTeacherWordsElementData] = useState<ElementData<TeacherWordsRegionContent> | null>(null);
    const { executeFunc } = useCommonFunction();

    // const courseBGMAudioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (lectureStore.courseBGM) {
            lectureStore.playCourseBGM();
        }

        return () => {
            lectureStore.pauseCourseBGM();
            lectureStore.resetCourseBGM();
        }
    }, [])

    //从子组件获取handleSubmit方法，多选按钮组的提交方法
    const callHandleSubmit = async () => {
        if (btnGroupRef.current) {
            await btnGroupRef.current.handleSubmit();
            console.log('handleSubmit');
        }
    };

    const updateBlockTrace = async (newBlockTraceData: BlockTraceData) => {
        try {
            await service.put('/block_trace', newBlockTraceData.toJSON());
        } catch (error) {
            console.log('error', error)
        }
    };

    const postProcess = async () => {
        console.log('postProcess');

        let elements = learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.filter(
            (ele => (ele.type === 110)
                && (ele.content as AIChatRegionContent).targetVarsStr
                && (ele.content as AIChatRegionContent).targetVarsStr !== ""));

        // 下面这个循环专门检查110的chat set var
        // chat set var的逻辑是从chatList的尾巴往头找，主要看带有usedToSetVar的消息
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].type === 110
                && (elements[i].content as AIChatRegionContent).targetVarsStr !== "") {
                console.log('targetVarsStr', (elements[i].content as AIChatRegionContent).targetVarsStr);
                const vars = (elements[i].content as AIChatRegionContent).targetVarsStr.split(',').map(item => item.trim());
                for (let k = (elements[i].content as AIChatRegionContent).chatList.length - 1; k >= 0; k--) {
                    const msg = (elements[i].content as AIChatRegionContent).chatList[k];
                    // 文字选择
                    if (msg.blanks.length > 0
                        && msg.blanks[0].selectedChoice !== "重新生成"
                        && msg.usedToSetVar) {
                        //目前只考虑有填空而且选一个的情况
                        const key = vars.pop()!;
                        const value = msg.blanks[0].selectedChoice;
                        console.log('targetVarsStr key', key, 'value', value);
                        lectureStore.setLectureVar(key, value);
                        // 这里也写进数据库
                        const newBlockTraceData = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                        newBlockTraceData.extras[key] = value;
                        learnedBlockList[lectureStore.currentBlockIndex].extras[key] = value;
                        updateBlockTrace(newBlockTraceData);

                        if (vars.length === 0) {
                            break;
                        }

                        continue;
                    }
                    // 生成图片选择
                    if (msg.type === 4) {
                        //目前只考虑选一个的情况
                        const key = vars.pop()!;
                        const value = msg.mediaInfo?.mediaUrl;

                        lectureStore.setLectureVar(key, value);
                        // 这里也写进数据库
                        const newBlockTraceData = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                        newBlockTraceData.extras[key] = value;
                        learnedBlockList[lectureStore.currentBlockIndex].extras[key] = value;
                        updateBlockTrace(newBlockTraceData);

                        if (vars.length === 0) {
                            break;
                        }

                        continue;
                    }
                }
            }
        }
        // 处理110, 106的postProcessHandlers
        elements = learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.filter(
            (ele => (ele.type === 106 || ele.type === 110)
                && (ele.content as (AIChatRegionContent | BtnGenericGroupRegionContent)).postProcessHandlers.length > 0));

        let total = 0;
        for (let i = 0; i < elements.length; i++) {
            const eleContent = elements[i].content as (AIChatRegionContent | BtnGenericGroupRegionContent);

            for (let j = 0; j < eleContent.postProcessHandlers.length; j++) {
                total++;
            }
        }

        let completed = 0;
        for (let i = 0; i < elements.length; i++) {
            const eleContent = elements[i].content as (AIChatRegionContent | BtnGenericGroupRegionContent);
            for (let j = 0; j < eleContent.postProcessHandlers.length; j++) {
                const handler = eleContent.postProcessHandlers[j];
                console.log('handler', handler);

                let finalPrompt = handler.prompt;
                // 判断是否要rewrite
                if (containsVariablePattern(handler.prompt)) {
                    finalPrompt = rewriteText(handler.prompt);
                }

                let context: ChatMessage[] = [];
                // 如果是AI Chat组件
                if (elements[i].type === 110 && (elements[i].content as AIChatRegionContent).chatList.length > 0) {
                    // 取最后一条消息
                    // TODO: 未来可能需要加入chat_list的所有消息
                    const pos: number = (elements[i].content as AIChatRegionContent).chatList.length - 1;
                    if (handler.mode === "") {
                        // default Text mode
                        context = [(elements[i].content as AIChatRegionContent).chatList[pos]];

                        const requestParams = LLMCallRequestData.genLLMCallParams(
                            finalPrompt,
                            userInfoStore.userInfoData.id,
                            userInfoStore.userInfoData.name,
                            false,
                            context
                        );

                        await requestLLMCallV2(requestParams).then((res) => {
                            if (res) {
                                console.log('res.content :', res.content);
                                lectureStore.setLectureVar(handler.destVar, res.content);
                                const key = DataDefinition.toCamelCase(handler.destVar);
                                // 这里也写进数据库
                                const newBlockTraceData = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                                newBlockTraceData.extras[key] = res.content;
                                learnedBlockList[lectureStore.currentBlockIndex].extras[key] = res.content;
                                console.log("learnedBlockList[lectureStore.currentBlockIndex].extras", Object.keys(learnedBlockList[lectureStore.currentBlockIndex].extras));
                                updateBlockTrace(newBlockTraceData);
                                completed++;
                            } else {
                                console.error('res is null');
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        });

                    } else if (handler.mode === "@getlastMessage") {
                        // 一个特殊的用法，用来存最后一个消息
                        const lastMsg = (elements[i].content as AIChatRegionContent).chatList[pos];
                        console.log('getlastMessage', lastMsg);
                        lectureStore.setLectureVar(handler.destVar, lastMsg.rawContent);
                        const key = DataDefinition.toCamelCase(handler.destVar);
                        // 这里也写进数据库
                        const newBlockTraceData = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                        newBlockTraceData.extras[key] = lastMsg.rawContent;
                        learnedBlockList[lectureStore.currentBlockIndex].extras[key] = lastMsg.rawContent;
                        console.log("learnedBlockList[lectureStore.currentBlockIndex].extras", Object.keys(learnedBlockList[lectureStore.currentBlockIndex].extras));
                        updateBlockTrace(newBlockTraceData);
                        completed++;
                    }
                }
            }
        }

        async function waitForAll() {
            if (total > 0) {
                while (completed < total) {
                    console.log('completed', completed, 'total', total);
                    await new Promise(resolve => setTimeout(resolve, 1000)); // 延时 100 毫秒
                    console.log('wait');
                }
                console.log('All handlers are completed');
                // 这里可以进行后续操作
            }
        }

        await waitForAll();
        console.log('waitForAll done');


        // 处理118的post Prompt, 115, 116的类似逻辑在useCommonButton里处理
        // elements = learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.filter(
        //     (ele => (ele.type === 118)
        //         && (ele.content as DragClassifyRegionContent).postPrompt !== ""));

        // for (let i = 0; i < elements.length; i++) {
        //     const eleContent = (elements[i].content as (DragClassifyRegionContent));
        //     const prompt = eleContent.convertToText() + eleContent.postPrompt;
        //     console.log('prompt @line 233', prompt);
        //     const request = LLMCallRequestData.genLLMCallParams(prompt,
        //         userInfoStore.userInfoData.id, userInfoStore.userInfoData.name, false);
        //     const response: any = await requestLLMCall(request);
        //     const res = await response.json();
        //     // 这里我们只期待一个文本回复
        //     console.log('res', res.data.content);
        //     if (eleContent.postFunctionCall?.funcName) {
        //         // 我们要人为改一个参数
        //         if (eleContent.postFunctionCall && eleContent.postFunctionCall.funcParams) {
        //             eleContent.postFunctionCall.funcParams['newWords'] = res.data.content;
        //         }
        //         console.log("exec post function", eleContent.postFunctionCall);
        //         executeFunc(eleContent.postFunctionCall?.funcName, eleContent.postFunctionCall?.funcParams);

        //         // TODO: 目前这个是个dirty hack
        //         // 这里可能需要等几秒
        //         function sleep(ms: number) {
        //             return new Promise(resolve => setTimeout(resolve, ms));
        //         }

        //         await sleep(5000);
        //     }
        // }

        // ###############################################################
        // 需要继续处理block level的postHandlers
        let completed2 = 0;
        let total2 = 0

        if (learnedBlockList[lectureStore.currentBlockIndex].extras &&
            learnedBlockList[lectureStore.currentBlockIndex].extras.postProcessHandlers) {

            total2 = learnedBlockList[lectureStore.currentBlockIndex].extras.postProcessHandlers.length;
            for (let n = 0; n < learnedBlockList[lectureStore.currentBlockIndex].extras.postProcessHandlers.length; n++) {
                const handler = learnedBlockList[lectureStore.currentBlockIndex].extras.postProcessHandlers[n];
                console.log('blk post handler', handler);

                let finalPrompt2 = handler.prompt;
                // 判断是否要rewrite
                if (containsVariablePattern(handler.prompt)) {
                    finalPrompt2 = rewriteText(handler.prompt);
                    console.log('finalPrompt2', finalPrompt2);
                }
                // 这里要注意如果handler没有正常解析，这个if可能不被执行，导致completed2不增加
                if (handler.mode === "") {
                    const requestParams2 = LLMCallRequestData.genLLMCallParams(
                        finalPrompt2,
                        userInfoStore.userInfoData.id,
                        userInfoStore.userInfoData.name,
                        false
                    );

                    requestLLMCallV2(requestParams2).then((res) => {
                        if (res) {
                            console.log('res.content from api2:', res.content);
                            lectureStore.setLectureVar(handler.destVar, res.content);
                            const key = DataDefinition.toCamelCase(handler.destVar);
                            // 这里也写进数据库
                            const newBlockTraceData2 = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                            newBlockTraceData2.extras[key] = res.content;
                            learnedBlockList[lectureStore.currentBlockIndex].extras[key] = res.content;
                            console.log("learnedBlockList[lectureStore.currentBlockIndex].extras -- api2", Object.keys(learnedBlockList[lectureStore.currentBlockIndex].extras));
                            updateBlockTrace(newBlockTraceData2);
                            completed2++;
                        } else {
                            console.error('res is null');
                        }
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                } else if (handler.mode === "draw") {
                    // 直接call画图
                    const api = new MultimediaAPI();
                    let vendor: string = '';
                    if (import.meta.env.VITE_OSS_SITE === 'CloudFront') {
                        vendor = "OpenAI";
                    } else if (import.meta.env.VITE_OSS_SITE === 'Aliyun') {
                        vendor = "Zhipu";
                    }
                    console.log("vendor", vendor);
                    const data = {
                        "request_id": "request_" + genUUIDv4(),
                        "prompt": finalPrompt2,
                        "config": {
                            "vendor": vendor
                        }
                    };
                    const res: any = await api.imageGen(data);
                    console.log('res of imageGen', res);
                    lectureStore.setLectureVar(handler.destVar, res['file_url']);
                    const key = DataDefinition.toCamelCase(handler.destVar);
                    // 这里也写进数据库
                    const newBlockTraceData = cloneDeep(learnedBlockList[lectureStore.currentBlockIndex]);
                    newBlockTraceData.extras[key] = res.data['file_url'];
                    learnedBlockList[lectureStore.currentBlockIndex].extras[key] = res.data['file_url'];
                    console.log("learnedBlockList[lectureStore.currentBlockIndex].extras", Object.keys(learnedBlockList[lectureStore.currentBlockIndex].extras));
                    updateBlockTrace(newBlockTraceData);
                    completed2++;
                }
            }
        }

        async function waitForAll2() {
            if (total2 > 0) {
                while (completed2 < total2) {
                    await new Promise(resolve => setTimeout(resolve, 1000)); // 延时 100 毫秒
                    console.log('wait2', completed2, total2);
                }
                console.log('All blk handlers are completed');
                // 这里可以进行后续操作
            }
        }

        await waitForAll2();
    };

    const handleCloseChat = () => {
        commonStatusStore.closeDialog();
    }

    // 给用户奖励  
    const rewardUser = async (data: any) => {
        try {
            const res = await userManagementAPI.UpdateUserAssetsExpress(data);
            if (res.status === 'success') {
                userInfoStore.updateUserAssetsFromData(res.data)
            }
        } catch (error) {
            console.error('Error reward user', error);
        }
    }

    const handleSubmit = async () => {
        if (isStudyingMode()) {
            // 如果是上课模式
            await callHandleSubmit();
            if (hasCorrectAnswer) return;
            await postProcess();
            console.log("after postProcess in handleSubmit");
        } else {
            console.log("replay 模式");
        }
        await lectureStore.continueFunc();
    }

    const handleContinue = async () => {
        // if (import.meta.env.PROD) {
        //     mixpanel.track('handleContinue-Enter');
        // }
        setIsLoading(true);

        if (isStudyingMode()) {
            // 如果是上课模式
            throttledPlayBtnClickSound();
            if (!showSubmitBtn) {
                callHandleSubmit();
            }

            await postProcess();
        } else {
            console.log("replay 模式");
        }

        if (learnedBlockList[lectureStore.currentBlockIndex]?.extras?.rewarded_coins_num &&
            learnedBlockList[lectureStore.currentBlockIndex]?.extras?.rewarded_coins_num !== 0) {
            const data = {
                user_id: userInfoStore.userInfoData.id,
                points_to_change: learnedBlockList[lectureStore.currentBlockIndex]?.extras?.rewarded_coins_num,
            }
            await rewardUser(data);
        }
        await lectureStore.continueFunc();
        setIsLoading(false);
        // if (import.meta.env.PROD) {
        //     mixpanel.track('handleContinue-Leave');
        // }
    };

    // 函数用于播放音频并限制其播放时间
    const playBtnClickSound = () => {
        const audio = continueBtnClickRef.current;
        audio.currentTime = 0;
        audio.play();

        setTimeout(() => {
            if (audio.duration > 2) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, 2000); // 最多播放两秒
    };

    // 判断是否是学习模式
    const isStudyingMode = () => {
        return lectureStore.currentBlockIndex >= lectureStore.learnedBlockList.length - 1;
    }

    // 防抖处理，防止连续点击
    const throttledPlayBtnClickSound = throttle(playBtnClickSound, 500);

    // 初始化elementRefs
    useEffect(() => {
        if (learnedBlockList.length > 0) {
            console.log('useEffect -- ', learnedBlockList, lectureStore.currentBlockIndex);
            learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.map(element => {
                const newElementRefsArr = cloneDeep(lectureStore.elementRefs)
                newElementRefsArr.push({ id: element.id, ref: React.createRef() });
                lectureStore.setElementRefs(newElementRefsArr);
            })
        }
        return () => {
            lectureStore.setElementRefs([]);
        }
    }, [originBlockList.length, lectureStore.currentBlockIndex])

    // 每次lectureStore.currentBlockIndex变化都会调用这个useEffect，一开始
    // currentBlockIndex是-1
    // 条件满足后，我们会设一个定时器，用blkTimeout开控制时间
    useEffect(() => {

        let timer = null;
        if (lectureStore.currentBlockIndex >= 0 && learnedBlockList.length > 0
            && learnedBlockList[lectureStore.currentBlockIndex].traceType === 1) {
            if (learnedBlockList[lectureStore.currentBlockIndex].extras.blkTimeout > 0) {
                timer = setTimeout(() => {
                    setTimeoutFlag(true);
                    console.log('Timeout 了');
                }, learnedBlockList[lectureStore.currentBlockIndex].extras.blkTimeout * 1000);
            }
        }


        // 清理函数
        return () => {
            // 清除定时器
            if (timer) clearTimeout(timer);
            setTimeoutFlag(false);
        };
        // originBlockList可能是懒加载，一开始是空的
    }, [lectureStore.currentBlockIndex]);

    useEffect(() => {
        return () => {
            lectureStore.setCurrentBlockIndex(-1);
        }
    }, [])

    //刚进来设置当前blockIndex为learnedBlockList的最后一个
    useEffect(() => {
        if (learnedBlockList.length - 1 >= 0) {
            lectureStore.setCurrentBlockIndex(learnedBlockList.length - 1);
            // if (import.meta.env.PROD) {
            //     mixpanel.track('setCurrentBlockIndex', {
            //         'index': learnedBlockList.length,
            //     });
            // }
        }
    }, [lectureStore, learnedBlockList.length]);

    //设置是否显示操作区域
    useEffect(() => {
        //如果learnedBlockList[lectureStore.currentBlockIndex]?.trace_info中有type为103，
        // 并且这一项的content的onclick_func的值为startLearn，则把showOperationArea设为false
        // 如果content的onclick_func的值为navigate，也把showOperationArea设为false
        if (learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.find(
            item => item.type === 103 && ((item.content as ButtonRegionContent).onclickFunc?.funcName === 'startLearn')
                || (item.content as ButtonRegionContent).onclickFunc?.funcName === 'navigate')) {
            console.log("here we are -- startLearn or navigate");
            setShowOperationArea(false);
        } else if (learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.find(item => item.type === 104
            && (item.content as AdvancedButtonRegionContent).children?.buttons?.find(btn => btn.content.onclickFunc?.funcName === 'startLearn'))) {
            console.log("here we are -- startLearn 2");
            setShowOperationArea(false);
        } else if (learnedBlockList[lectureStore.currentBlockIndex]) {
            setShowOperationArea(true);
        }
    }, [learnedBlockList, lectureStore.currentBlockIndex])

    useEffect(() => {
        const currentBlock = learnedBlockList[lectureStore.currentBlockIndex];
        if (!currentBlock) {
            return;
        }
        const { traceInfo: traceInfo } = currentBlock;

        const hasType = traceInfo.some(item => item.type === 105 || item.type === 106);
        const hasGenSongElement = traceInfo.some(item => item.type === 109);
        const hasChatGptElement = traceInfo.some(item => item.type === 110);
        const hasCorrectAnswer = traceInfo.some(item => (item.type === 106 || item.type === 105) &&
            (item.content as (BtnTextGroupRegionContent | BtnGenericGroupRegionContent))?.correctAnswer?.length > 0);
        const hasDragClassifyElement = traceInfo.some(item => item.type === 118);
        const hasTeacherWordsElement = traceInfo.some(item => item.type === 111);

        setShowSubmitBtn(hasType);
        setHasCorrectAnswer(hasType && hasCorrectAnswer);
        setHasGenSongElement(hasGenSongElement);
        setHasChatGptElement(hasChatGptElement);
        setHasDragClassifyElement(hasDragClassifyElement);
        if (hasTeacherWordsElement) {
            setTeacherWordsElementData(
                traceInfo.find(item => item.type === 111) as ElementData<TeacherWordsRegionContent>);
        }
    }, [learnedBlockList, lectureStore.currentBlockIndex]);

    //useEffect(() => {
    //   console.log(lectureStore.currentBlockIndex, originBlockList.length + 1);
    //   if(lectureStore.currentBlockIndex === originBlockList.length) {
    //       setIsLastBlock(true);
    //       console.log('true');
    //   } else {
    //       setIsLastBlock(false);
    //       console.log('false');
    //   }
    //   console.log('isLastBlock', isLastBlock);
    //}, [learnedBlockList, lectureStore.currentBlockIndex]);

    useEffect(() => {
        // 定义调整高度的函数  
        const handleResize = () => {
            if (window.innerWidth > 1280) {
                if (showSubmitBtn && commonStatusStore.isSubmitted && commonStatusStore.hasCorrectAnswer) {
                    setOperationAreaHeight(200);
                    setContainerHeight(window.innerHeight - 250);
                } else {
                    setOperationAreaHeight(0);
                    setContainerHeight(window.innerHeight - 110);
                }
            } else if (window.innerWidth >= 769 && window.innerWidth <= 1280) {
                if (showSubmitBtn && commonStatusStore.isSubmitted && commonStatusStore.hasCorrectAnswer) {
                    setOperationAreaHeight(120);
                    setContainerHeight(window.innerHeight - 155);
                } else {
                    setOperationAreaHeight(0);
                    setContainerHeight(window.innerHeight - 90);
                }
            } else {
                setContainerHeight(window.innerHeight - 130);
            }
        };

        // 初始化调用一次以确保高度正确  
        handleResize();

        // 监听窗口resize事件  
        window.addEventListener('resize', handleResize);

        // 清除事件监听器  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showSubmitBtn, commonStatusStore.isSubmitted]);


    const songGeneratedOrNot = () => {
        if (currentBlock?.extras?.sono_song) {
            commonStatusStore.setGenSongFinished(true);
            console.log("songGeneratedOrNot - Yes");
            return true;
        }
        if (commonStatusStore.genSongFinished) {
            console.log("songGeneratedOrNot - Yes");
            return true;
        }
        console.log("songGeneratedOrNot - No");
        return false;
    };

    useEffect(() => {
        handleCloseChat();
    }, [lectureStore.currentBlockIndex]);

    return (
        <div className={styles.studentBlock} style={{
            backgroundImage: `url(${convertUrl(learnedBlockList[lectureStore.currentBlockIndex]?.extras.background) || ''})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: `${window.innerHeight}px`,
        }}>
            <div className={styles.topArea}>
                {originBlockList.length === learnedBlockList.length &&
                    <div className={styles.goFirst}
                        onClick={() => {
                            // if (import.meta.env.PROD) {
                            //     mixpanel.track('返回第一页');
                            // }
                            lectureStore.setCurrentBlockIndex(1);
                        }}>
                        <img src={FirstPageIcon} className={styles.homeIcon} alt="返回" />
                    </div>}
                <ProgressBar partSegments={lectureStore.partSegments} currentPos={lectureStore.currentBlockIndex} />
                <div className={styles.pageNumberText} >
                    {
                        learnedBlockList.length > 0
                        && `第${lectureStore.currentBlockIndex + 1}页/共${originBlockList.length}页`}
                </div>
                <div className={styles.goBack} onClick={() => {
                    // if (import.meta.env.PROD) {
                    //     mixpanel.track('返回教室');
                    // }
                    navigate(`/classroom`);
                }
                }>
                    <img src={HomeIcon} className={styles.homeIcon} alt="返回" />
                </div>
                <div className={styles.rightArea}>
                    <div className={styles.userAssets}>
                        <div className={styles.assetsItem}>
                            <img src={CoinsIcon} alt="金币" />
                            <span>{userInfoStore.userAssetData.numCoins}</span>
                        </div>
                        {
                            // 钻石功能暂时关闭
                            false &&
                            <div className={styles.assetsItem}>
                                <img src={DiamondIcon} alt="钻石" />
                                <span>{userInfoStore.userAssetData.numDiamonds}</span>
                            </div>
                        }
                    </div>
                    <div className={styles.userAvatar}>
                        <img src={userInfoStore.userInfoData.avatarUrl ? userInfoStore.userInfoData.avatarUrl : UserPng} alt="userAvatar" />
                    </div>
                </div>
            </div>
            <div className={styles.containerBox}
                style={{
                    height: `${containerHeight}px`,
                    alignItems: containerHeight < 600 ? "flex-start" : "center",
                }}>
                <div className={styles.cleanUpBtn}>
                    <ClearRecordModal
                        userId={userInfoStore.userInfoData.id}
                        sectionId={Number(sectionId)}
                        currentPageInCourse={lectureStore.currentBlockIndex}
                        mode="teacher"
                    />
                </div>
                <div className={styles.container}>
                    {learnedBlockList.length > 0 &&
                        learnedBlockList[lectureStore.currentBlockIndex]?.traceInfo.map((element, index) => {
                            const elementRef = lectureStore.elementRefs.find(item => item.id === element.id)?.ref;
                            return <RenderElement key={index}
                                elementData={element}
                                isEditable={false}
                                index={index}
                                blockTraceData={learnedBlockList[lectureStore.currentBlockIndex]}
                                lectureStore={lectureStore}
                                innerRef={btnGroupRef}
                                elementRef={elementRef}
                                AIChatRef={AIChatRef}
                            />
                        })
                    }
                </div>
                {
                    commonStatusStore.isDialogOpen &&
                    learnedBlockList[lectureStore.currentBlockIndex] &&
                    // Teacher的侧边栏是在这里设置的
                    <TeacherChatModal
                        handleCloseChat={handleCloseChat}
                        blockTraceData={learnedBlockList[lectureStore.currentBlockIndex]}
                    />
                }
            </div>
            {
                showOperationArea &&
                <div className={styles.operationArea}
                    style={{
                        ...(operationAreaHeight !== 0 ? { height: operationAreaHeight } : {}),
                        justifyContent: showSubmitBtn &&
                            commonStatusStore.isSubmitted &&
                            commonStatusStore.hasCorrectAnswer
                            ? "space-between" : ''
                    }}>
                    <Button
                        onClick={lectureStore.previousStep}
                        className={styles.previousBtn}
                        disabled={isLoading}
                    >
                        上一步
                    </Button>
                    {
                        showSubmitBtn && (!commonStatusStore.isSubmitted ?
                            <Button
                                onClick={handleSubmit}
                                disabled={!commonStatusStore.isSelected}
                                className={`${commonStatusStore.isSelected ?
                                    styles.submitAbleBtn : styles.submitDisableBtn}`}
                            >
                                提交
                            </Button> :
                            <Button
                                onClick={handleContinue}
                                className={`${timeoutFlag ? styles.btnAttn : styles.continueBtn}`}
                                loading={isLoading}
                            >
                                {isLoading ? '跳转中...' : '继续'}
                            </Button>)
                    }
                    {
                        !isLastBlock && <>
                            {
                                hasGenSongElement &&
                                <Button
                                    onClick={handleContinue}
                                    className={`${timeoutFlag ? styles.btnAttn : styles.continueBtn} 
                            ${songGeneratedOrNot() ? "" : styles.continueDisableBtn}`}
                                    disabled={!songGeneratedOrNot()}
                                    loading={commonStatusStore.btnLoading}
                                    style={{ width: commonStatusStore.btnLoading ? "170px" : "140px" }}
                                >
                                    {commonStatusStore.btnLoading ? '歌曲生成中...' : '继续'}
                                </Button>
                            }
                            {
                                // 这个只针对有AIChat组件的页面
                                hasChatGptElement &&
                                <Button
                                    onClick={() => {

                                        // 判断是否是replay mode还是上课模式
                                        if (isStudyingMode()) {
                                            // 上课模式
                                            if (commonStatusStore.isFetching) return;
                                            if (commonStatusStore.buttonFlashing) return;
                                            if (!commonStatusStore.isClickPreset) {
                                                commonStatusStore.setButtonFlashing(true);
                                                return;
                                            }
                                        }
                                        handleContinue();
                                    }}
                                    loading={isLoading}
                                    className={`${timeoutFlag ? styles.btnAttn : styles.continueBtn}
                            ${(isStudyingMode() && !commonStatusStore.isClickPreset || commonStatusStore.isFetching)
                                            ? styles.continueDisableBtn : ""}`}
                                >
                                    {isLoading ? '跳转中...' : '继续'}
                                </Button>
                            }
                            {
                                hasDragClassifyElement &&
                                <Button
                                    onClick={() => {
                                        if (!commonStatusStore.isClassifyFinished) return;
                                        handleContinue();
                                    }}
                                    loading={isLoading}
                                    className={`${timeoutFlag ? styles.btnAttn : styles.continueBtn}
                            ${!commonStatusStore.isClassifyFinished ? styles.continueDisableBtn : ""}`}
                                >
                                    {isLoading ? '跳转中...' : '继续'}
                                </Button>
                            }
                            {
                                !showSubmitBtn && !hasGenSongElement && !hasChatGptElement && !hasDragClassifyElement &&
                                <Button
                                    onClick={handleContinue}
                                    className={`${timeoutFlag ? styles.btnAttn : styles.continueBtn}`}
                                    loading={isLoading}
                                >
                                    {isLoading ? '跳转中...' : '继续'}
                                </Button>
                            }
                        </>
                    }

                </div>
            }
        </div>
    )
})

export default StudentBlock;